import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'axios-hooks';
import LRU from 'lru-cache';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0';
import './assets/css/demo.css';
import './assets/css/pe-icon-7-stroke.css';

import App from './app';

/* Axios configuration */
const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});
instance.interceptors.request.use(
	function (config) {
		const token = window.localStorage.getItem('alfi-token');
		config.headers.authorization = `Bearer ${token}`;
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

instance.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status === 401) {
			if (window.location.pathname !== '/login') {
				window.localStorage.clear();
				window.location = '/login';
			}
		}
		return Promise.reject(error);
	},
);

const cache = new LRU({ max: 1 });
configure({ axios: instance, cache });

ReactDOM.render(<App />, document.getElementById('root'));
