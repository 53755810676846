import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';

import AdminNavbar from 'components/Navbars/AdminNavbar';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
// import FixedPlugin from 'components/FixedPlugin/FixedPlugin.jsx';

import { style } from 'variables/Variables.jsx';

import routes from 'routes.js';

import image from 'assets/img/sidebar-3.jpg';

class Admin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			groups: [],
			groupId: '',
			_notificationSystem: null,
			image: image,
			color: 'black',
			hasImage: true,
			fixedClasses: 'dropdown show-dropdown open',
			refreshGroups: 0,
		};
	}
	handleNotificationClick = ({ message, level = 'info' }) => {
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-info" />,
			message: <div>{message}</div>,
			level,
			position: 'tr',
			autoDismiss: 5,
		});
	};

	onGroupChanged = () => {
		this.setState({ refreshGroups: this.state.refreshGroups + 1 });
	};

	getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/admin') {
				return (
					<Route
						path={prop.layout + prop.path}
						exact={prop.exact || false}
						render={(props) => (
							<prop.component
								{...props}
								countries={this.props.countries}
								groupId={this.state.groupId}
								handleClick={this.handleNotificationClick}
								levels={this.props.levels}
								teams={this.props.teams}
								onGroupChanged={this.onGroupChanged}
							/>
						)}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	getBrandText = (path) => {
		const withoutAdmin = path.replace('/admin', '');
		const currentRoute = routes.find((r) => r.path.includes(withoutAdmin));
		if (currentRoute) {
			return currentRoute.name;
		} else {
			return '';
		}
	};
	handleImageClick = (image) => {
		this.setState({ image: image });
	};
	handleColorClick = (color) => {
		this.setState({ color: color });
	};
	handleHasImage = (hasImage) => {
		this.setState({ hasImage: hasImage });
	};
	handleFixedClick = () => {
		if (this.state.fixedClasses === 'dropdown') {
			this.setState({ fixedClasses: 'dropdown show-dropdown open' });
		} else {
			this.setState({ fixedClasses: 'dropdown' });
		}
	};
	componentDidMount() {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		// this.fetchGroups();
	}

	fetchGroups = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/admin/group`,
			{
				headers: {
					authorization: `Bearer ${window.localStorage.getItem('alfi-token')}`,
				},
			},
		);
		const json = await response.json();
		this.setState({ groups: json.data });
	};

	componentDidUpdate(e, prevState) {
		if (
			window.innerWidth < 993 &&
			e.history.location.pathname !== e.location.pathname &&
			document.documentElement.className.indexOf('nav-open') !== -1
		) {
			document.documentElement.classList.toggle('nav-open');
		}
		if (e.history.action === 'PUSH') {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			this.refs.mainPanel.scrollTop = 0;
			// Ugly hack since <Sidebar /> has a bug that
			// create multiple div#bodyClick containers
			// making the sidebar open all time in mobiles/tablets.
			// So sorry future me or developer friend
			// Not my call to buy a template
			const bodyClickContainer = document.querySelectorAll('div#bodyClick');
			if (bodyClickContainer.length > 0) {
				bodyClickContainer.forEach((el) => {
					el.parentElement.removeChild(el);
				});
			}
		}
		if (prevState.refreshGroups !== this.state.refreshGroups) {
			this.fetchGroups();
		}
	}

	onChangeGroup = (e) => {
		this.setState({
			groupId: Number(e.target.value),
		});
	};

	onLogout = (eventKey) => {
		if (eventKey === 3) {
			window.localStorage.removeItem('alfi-token');
			this.props.history.push('/login');
		}
	};

	render() {
		const noToken =
			Boolean(window.localStorage.getItem('alfi-token')) === false;
		if (noToken) {
			return <Redirect to="/login" />;
		}

		return (
			<div className="wrapper">
				<NotificationSystem ref="notificationSystem" style={style} />
				<Sidebar
					{...this.props}
					routes={routes.filter((r) => r.showInSidebar)}
					image={this.state.image}
					color="alfi-green"
					hasImage={this.state.hasImage}
					onLogout={this.onLogout}
				/>
				<div id="main-panel" className="main-panel" ref="mainPanel">
					<AdminNavbar
						{...this.props}
						groupId={this.state.groupId}
						groups={this.state.groups}
						onChangeGroup={this.onChangeGroup}
						onLogout={this.onLogout}
						brandText={this.getBrandText(this.props.location.pathname)}
					/>
					<Switch>{this.getRoutes(routes)}</Switch>
					<Footer />
					{/* <FixedPlugin
						handleImageClick={this.handleImageClick}
						handleColorClick={this.handleColorClick}
						handleHasImage={this.handleHasImage}
						bgColor={this.state['color']}
						bgImage={this.state['image']}
						mini={this.state['mini']}
						handleFixedClick={this.handleFixedClick}
						fixedClasses={this.state.fixedClasses}
					/> */}
				</div>
			</div>
		);
	}
}

export default Admin;
