import Dashboard from 'views/Dashboard2.jsx';
import DashboardGroup from 'views/DashboardGroup.jsx';
// import UsersView from 'views/Users/UsersView.jsx';
// import UsersCreateView from 'views/Users/UsersCreateView';
// import UsersEditView from 'views/Users/UsersEditView';
// import UsersImportView from 'views/Users/UsersImportView';
// import UsersSkillsView from 'views/Users/UsersSkillsView';
// import GroupsView from 'views/Groups/GroupsView';
// import GroupsCreateView from 'views/Groups/GroupsCreateView';
// import GroupsEditView from 'views/Groups/GroupsEditView';
// import GroupsDetailsView from 'views/Groups/GroupsDetailsView';
// import SkillsView from 'views/Skills/SkillsView';
// import SkillsCreateView from 'views/Skills/SkillsCreateView';
// import SkillsEditView from 'views/Skills/SkillsEditView';
// import LevelsView from 'views/Levels/LevelsView';
// import LevelsCreateView from 'views/Levels/LevelsCreateView';
// import LevelsEditView from 'views/Levels/LevelsEditView';
// import TestsView from 'views/Tests/TestsView';
// import TestsCreateView from 'views/Tests/TestsCreateView';
// import TestsEditView from 'views/Tests/TestsEditView';
// import QuestionsView from 'views/Questions/QuestionsView';
// import QuestionsCreateView from 'views/Questions/QuestionsCreateView';
// import QuestionsEditView from 'views/Questions/QuestionsEditView';
// import AnswersView from 'views/Answers/AnswersView';
// import AnswersCreateView from 'views/Answers/AnswersCreateView';
// import AnswersEditView from 'views/Answers/AnswersEditView';
import UsersProductsView from 'views/Users/UsersProductsView.jsx';

const dashboardRoutes = [
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'pe-7s-graph',
		component: Dashboard,
		layout: '/admin',
		showInSidebar: true,
	},
	{
		path: '/dashboard-group',
		name: 'Dashboard Grupos',
		icon: 'pe-7s-graph',
		component: DashboardGroup,
		layout: '/admin',
		showInSidebar: true,
	},
	// {
	// 	path: '/my-user',
	// 	name: 'User Profile',
	// 	icon: 'pe-7s-user',
	// 	component: UserProfile,
	// 	layout: '/admin',
	// 	exact: true,
	// 	showInSidebar: true,
	// },
	// {
	// 	path: '/users',
	// 	name: 'Usuarios',
	// 	icon: 'pe-7s-user',
	// 	component: UsersView,
	// 	layout: '/admin',
	// 	exact: true,
	// 	showInSidebar: true,
	// },
	// {
	// 	path: '/users/new',
	// 	name: 'Crear Usuario',
	// 	icon: 'pe-7s-add-user',
	// 	component: UsersCreateView,
	// 	layout: '/admin',
	// 	exact: true,
	// 	showInSidebar: true,
	// },
	// {
	// 	path: '/users/:id/edit',
	// 	name: 'Editar Usuario',
	// 	icon: 'pe-7s-note2',
	// 	component: UsersEditView,
	// 	layout: '/admin',
	// 	showInSidebar: false,
	// },
	// {
	// 	component: UsersImportView,
	// 	exact: true,
	// 	icon: 'pe-7s-upload',
	// 	layout: '/admin',
	// 	name: 'Importar Usuarios',
	// 	path: '/users/import',
	// 	showInSidebar: true,
	// },
	// {
	// 	component: GroupsView,
	// 	exact: true,
	// 	icon: 'fa pe-7s-box2',
	// 	layout: '/admin',
	// 	name: 'Grupos',
	// 	path: '/groups',
	// 	showInSidebar: true,
	// },
	// {
	// 	path: '/groups/new',
	// 	name: 'Crear Grupo',
	// 	icon: 'pe-7s-add-user',
	// 	component: GroupsCreateView,
	// 	layout: '/admin',
	// 	exact: true,
	// 	showInSidebar: false,
	// },
	// {
	// 	path: '/groups/:id/edit',
	// 	name: 'Editar Grupo',
	// 	icon: 'pe-7s-note2',
	// 	component: GroupsEditView,
	// 	layout: '/admin',
	// 	showInSidebar: false,
	// },
	// {
	// 	path: '/groups/:id/details',
	// 	name: 'Detalle de Grupo',
	// 	icon: 'pe-7s-note2',
	// 	component: GroupsDetailsView,
	// 	layout: '/admin',
	// 	showInSidebar: false,
	// },
	// {
	// 	path: '/users/:id/skills',
	// 	name: 'Skills',
	// 	icon: 'pe-7s-note2',
	// 	component: UsersSkillsView,
	// 	layout: '/admin',
	// 	showInSidebar: false,
	// },
	// {
	// 	component: SkillsView,
	// 	exact: true,
	// 	icon: 'fa pe-7s-key',
	// 	layout: '/admin',
	// 	name: 'Skills',
	// 	path: '/skills',
	// 	showInSidebar: true,
	// },
	// {
	// 	component: SkillsCreateView,
	// 	icon: 'fa pe-7s-box2',
	// 	layout: '/admin',
	// 	name: 'Skills',
	// 	path: '/skills/new',
	// 	showInSidebar: false,
	// },
	// {
	// 	component: SkillsEditView,
	// 	icon: 'fa pe-7s-key',
	// 	layout: '/admin',
	// 	name: 'Skills',
	// 	path: '/skills/:id/edit',
	// 	showInSidebar: false,
	// },
	// {
	// 	component: LevelsView,
	// 	exact: true,
	// 	icon: 'fa pe-7s-star',
	// 	layout: '/admin',
	// 	name: 'Niveles',
	// 	path: '/levels',
	// 	showInSidebar: true,
	// },
	// {
	// 	component: LevelsCreateView,
	// 	icon: 'fa pe-7s-box2',
	// 	layout: '/admin',
	// 	name: 'Niveles',
	// 	path: '/levels/new',
	// 	showInSidebar: false,
	// },
	// {
	// 	component: LevelsEditView,
	// 	icon: 'fa pe-7s-key',
	// 	layout: '/admin',
	// 	name: 'Niveles',
	// 	path: '/levels/:id/edit',
	// 	showInSidebar: false,
	// },
	{
		path: '/users/products',
		name: 'Mundo Financiero',
		icon: 'pe-7s-cash',
		component: UsersProductsView,
		layout: '/admin',
		exact: true,
		showInSidebar: true,
	},
	// {
	// 	component: TestsView,
	// 	exact: true,
	// 	icon: 'pe-7s-cup',
	// 	layout: '/admin',
	// 	name: 'Pruebas',
	// 	path: '/tests',
	// 	showInSidebar: true,
	// },
	// {
	// 	component: TestsCreateView,
	// 	exact: true,
	// 	icon: 'pe-7s-cup',
	// 	layout: '/admin',
	// 	name: 'Pruebas',
	// 	path: '/tests/new',
	// 	showInSidebar: false,
	// },
	// {
	// 	component: TestsEditView,
	// 	exact: true,
	// 	icon: 'pe-7s-cup',
	// 	layout: '/admin',
	// 	name: 'Pruebas',
	// 	path: '/tests/:id/edit',
	// 	showInSidebar: false,
	// },
	// {
	// 	component: QuestionsView,
	// 	exact: true,
	// 	icon: 'pe-7s-help1',
	// 	layout: '/admin',
	// 	name: 'Preguntas',
	// 	path: '/questions',
	// 	showInSidebar: true,
	// },
	// {
	// 	component: QuestionsCreateView,
	// 	exact: true,
	// 	icon: 'pe-7s-help1',
	// 	layout: '/admin',
	// 	name: 'Preguntas',
	// 	path: '/questions/new',
	// 	showInSidebar: false,
	// },
	// {
	// 	component: QuestionsEditView,
	// 	exact: true,
	// 	icon: 'pe-7s-help1',
	// 	layout: '/admin',
	// 	name: 'Preguntas',
	// 	path: '/questions/:id/edit',
	// 	showInSidebar: false,
	// },
	// {
	// 	component: AnswersView,
	// 	exact: true,
	// 	icon: 'pe-7s-speaker',
	// 	layout: '/admin',
	// 	name: 'Respuestas',
	// 	path: '/answers',
	// 	showInSidebar: true,
	// },
	// {
	// 	component: AnswersCreateView,
	// 	exact: true,
	// 	icon: 'pe-7s-speaker',
	// 	layout: '/admin',
	// 	name: 'Respuestas',
	// 	path: '/answers/new',
	// 	showInSidebar: false,
	// },
	// {
	// 	component: AnswersEditView,
	// 	exact: true,
	// 	icon: 'pe-7s-speaker',
	// 	layout: '/admin',
	// 	name: 'Respuestas',
	// 	path: '/answers/:id/edit',
	// 	showInSidebar: false,
	// },
];

export default dashboardRoutes;
