import React, { useState, useEffect } from 'react';
import ChartistGraph from 'react-chartist';
import { Alert, Grid, Row, Col, Table } from 'react-bootstrap';

import { Card } from 'components/Card/Card.jsx';
import { StatsCard } from 'components/StatsCard/StatsCard.jsx';

import useAxios from 'axios-hooks';
import { Fragment } from 'react';
import './chart.css';

const colors = [
	'#3e5a99',
	'rgb(214, 59, 59)',
	'rgb(107 140 0)',
	'#dd4b39',
	'#9368e9',
	'#fb404b',
	'#ffa534',
	'#03e5fa',
	'#fb40c3',
	'#87cb16',
];
const pieOptions = {
	donut: true,
	donutWidth: 60,
	donutSolid: true,
	startAngle: 270,
	showLabel: true,
};

function Dashboard() {
	const [groupId, setGroupId] = useState();
	const [challengeName, setChallengeName] = useState('');
	const [worlds, setWorlds] = useState([]);
	const [showTeams, setShowTeams] = useState(false);
	const [dataAges, setDataAges] = useState({
		labels: [],
		series: [[]],
	});
	const [dataPie, setDataPie] = useState({
		labels: [],
		series: [],
		legend: [],
	});

	const [{ data: groups, error, response }] = useAxios({
		url: '/admin/reports/groups',
	});

	const [{ data }, executeGet] = useAxios(
		{
			url: `/admin/reports/groups/${groupId}/details`,
		},
		{ manual: true },
	);

	useEffect(() => {
		if (groupId) {
			executeGet();
		}
	}, [groupId]);

	function onGroupChange(e) {
		const current = groups.data.find((g) => g.id === Number(e.target.value));
		if (current) {
			setGroupId(current.id);
			setChallengeName(current.course ? current.course.title : '');
		}
	}

	function roundTo(num) {
		return Math.round(num * 100) / 100;
	}

	useEffect(() => {
		if (data && data.data) {
			let teamHasValue = 0;
			const teams = data.data.teams.reduce(
				(acum, item, index) => {
					let serie = 0;
					if (item.total) {
						serie = roundTo(
							(item.total / data.data.total_users_products) * 100,
						);
					} else {
						teamHasValue += 1;
					}
					acum.legend.push({ name: item.name, icon: colors[index] });
					acum.series.push({
						name: item.name,
						value: serie,
						className: `chart-${index}`,
					});
					return acum;
				},
				{
					legend: [],
					series: [],
					labels: [],
				},
			);
			setShowTeams(teamHasValue !== 3);
			setDataPie(teams);
		}
		if (data && data.data.worlds) {
			const w = data.data.worlds.reduce((acum, item) => {
				const newItem = { ...item };
				const cl = Object.entries(newItem.levels);
				const chart = cl.reduce(
					(acum, it, index) => {
						const serie = roundTo((it[1] / newItem.total) * 100);
						acum.legend.push({ name: it[0], icon: colors[index] });
						acum.series.push({
							name: it[0],
							value: serie,
							className: `chart-${index}`,
						});
						return acum;
					},
					{
						legend: [],
						series: [],
						labels: [],
					},
				);
				newItem.chart = chart;
				acum.push(newItem);
				return acum;
			}, []);
			setWorlds(w);
		}
		if (data && data.data.ages) {
			const ages = data.data.ages.reduce(
				(acum, item) => {
					acum.labels.push(item.name);
					acum.series[0].push(item.total);
					return acum;
				},
				{
					series: [[]],
					labels: [],
				},
			);
			setDataAges(ages);
		}
	}, [data]);

	function teamLegend() {
		return (
			<div className="legend">
				{dataPie.legend.map((item) => {
					return (
						<Fragment key={item.name}>
							<div
								className="react-chart-custom-legend"
								style={{
									backgroundColor: item.icon,
								}}
							/>{' '}
							{item.name}
						</Fragment>
					);
				})}
			</div>
		);
	}

	function worldsLegend(info) {
		return (
			<div className="legend">
				{info.map((item) => {
					return (
						<Fragment key={item.name}>
							<div
								className="react-chart-custom-legend"
								style={{
									backgroundColor: item.icon,
								}}
							/>{' '}
							{item.name}
						</Fragment>
					);
				})}
			</div>
		);
	}

	return (
		<div className="content">
			<Grid fluid>
				<Row>
					<Col sm={3}>
						<select
							style={{ marginBottom: 20 }}
							className="form-control"
							onChange={onGroupChange}
						>
							{!groupId && <option>Seleccionar Grupo</option>}
							{groups &&
								groups.data.map((g) => {
									return (
										<option key={g.id} value={g.id}>
											{g.name}
										</option>
									);
								})}
						</select>
					</Col>
				</Row>
			</Grid>
			{data ? (
				<Grid fluid>
					<Row>
						<Col lg={3} sm={4}>
							<StatsCard
								bigIcon={<i className="pe-7s-server text-warning" />}
								statsText="Usuarios"
								statsValue={data ? data.data.total_users_products : 0}
								statsIcon={<i className="fa fa-refresh" />}
								statsIconText="Total"
							/>
						</Col>
						<Col lg={3} sm={4}>
							<StatsCard
								bigIcon={<i className="fa fa-thumbs-up text-success" />}
								statsText="Clases Aprobadas"
								statsValue={data ? data.data.total_approved_lessons : 0}
								statsIcon={<i className="fa fa-calendar-o" />}
								statsIconText="Total"
							/>
						</Col>
						<Col lg={3} sm={4}>
							<StatsCard
								bigIcon={<i className="fa fa-thumbs-up text-danger" />}
								statsText="Clases Desaprobadas"
								statsValue={data ? data.data.total_failed_lessons : 0}
								statsIcon={<i className="fa fa-refresh" />}
								statsIconText="Total"
							/>
						</Col>
						<Col lg={3} sm={4}>
							<StatsCard
								bigIcon={<i className="fa fa-thumbs-up text-success" />}
								statsText="Niveles Aprobados"
								statsValue={data ? data.data.total_approved_tests : 0}
								statsIcon={<i className="fa fa-refresh" />}
								statsIconText="Total"
							/>
						</Col>
						<Col lg={3} sm={4}>
							<StatsCard
								bigIcon={<i className="fa fa-thumbs-down text-danger" />}
								statsText="Niveles Desaprobados"
								statsValue={data ? data.data.total_failed_tests : 0}
								statsIcon={<i className="fa fa-refresh" />}
								statsIconText="Total"
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Card
								statsIcon="fa fa-clock-o"
								title="Usuarios"
								category="Agrupados por Edad"
								stats="Total"
								content={
									<div className="ct-chart">
										<ChartistGraph data={dataAges} type="Bar" />
									</div>
								}
							/>
						</Col>
					</Row>
					<Row>
						{worlds.map((item) => {
							return (
								<Col md={6} key={item.name}>
									<Card
										statsIcon="fa fa-clock-o"
										title={item.name}
										stats="Total"
										content={
											<div
												id="chartPreferences"
												key={item.name}
												className="ct-chart ct-perfect-fourth"
											>
												<ChartistGraph
													data={item.chart}
													type="Pie"
													options={pieOptions}
												/>
											</div>
										}
										legend={
											<div className="legend">
												{worldsLegend(item.chart.legend)}
											</div>
										}
									/>
								</Col>
							);
						})}
						{showTeams && (
							<Col md={6}>
								<Card
									statsIcon="fa fa-clock-o"
									title="Productos Solicitados"
									category="Agrupados por Equipo"
									stats="Total"
									content={
										<div
											id="chartPreferences"
											className="ct-chart ct-perfect-fourth"
										>
											<ChartistGraph
												data={dataPie}
												type="Pie"
												options={pieOptions}
											/>
										</div>
									}
									legend={teamLegend()}
								/>
							</Col>
						)}
					</Row>
					<Row>
						<Col md={6}>
							<Card
								title="Marcas Favoritas"
								ctTableFullWidth
								ctTableResponsive
								content={
									<Table striped hover>
										<thead>
											<tr>
												<th>Marca</th>
												<th>Total</th>
											</tr>
										</thead>
										<tbody>
											{data &&
												data.data.brands.map((record) => {
													return (
														<tr key={record.name}>
															<td>{record.name}</td>
															<td>{record.total}</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								}
							/>
						</Col>
						<Col md={6}>
							<Card
								title="Temas Favoritos"
								ctTableFullWidth
								ctTableResponsive
								content={
									<Table striped hover>
										<thead>
											<tr>
												<th>Tema</th>
												<th>Total</th>
											</tr>
										</thead>
										<tbody>
											{data &&
												data.data.topics.map((record) => {
													return (
														<tr key={record.name}>
															<td>{record.name}</td>
															<td>{record.total}</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Card
								title="Ranking de Usuarios"
								ctTableFullWidth
								ctTableResponsive
								content={
									<Table striped hover>
										<thead>
											<tr>
												<th>#</th>
												<th>Nombre</th>
												<th>Email</th>
												<th>Tel.</th>
											</tr>
										</thead>
										<tbody>
											{data &&
												data.data.users.map((record, index) => {
													return (
														<tr key={record.user_id}>
															<td>{index + 1}</td>
															<td>{`${record.first_name} ${record.last_name}`}</td>
															<td>{record.email}</td>
															<td>{record.phone}</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								}
							/>
						</Col>
					</Row>
					<Row>
						{groupId && (
							<Col md={12}>
								<h1>Desafío: {challengeName}</h1>
							</Col>
						)}
						{data &&
							data.data.user_groups.map((user) => {
								return (
									<Col md={12} key={user.user_id}>
										<Card
											title={user.full_name}
											ctTableFullWidth
											ctTableResponsive
											content={
												<Table striped hover>
													<thead>
														<tr>
															<th>Clase</th>
															<th>Aciertos</th>
															<th>Errores</th>
															<th>¿Aprobó?</th>
														</tr>
													</thead>
													<tbody>
														{user.lessons.map((record) => {
															return (
																<tr key={record.lesson_id}>
																	<td>{record.lesson_name}</td>
																	<td>{record.total_questions_right}</td>
																	<td>{record.total_questions_wrong}</td>
																	<td>{record.approved ? 'Sí' : 'No'}</td>
																</tr>
															);
														})}
													</tbody>
												</Table>
											}
										/>
									</Col>
								);
							})}
					</Row>
				</Grid>
			) : error ? (
				<Alert bsStyle="danger">
					No tiene permisos para acceder a esta sección
				</Alert>
			) : (
				<Alert bsStyle="info">
					Seleccione un grupo para visualizar los datos
				</Alert>
			)}
		</div>
	);
}

export default Dashboard;
