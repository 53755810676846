import React from 'react';

import styles from './public.module.scss';
import LoginView from './../views/Login/LoginView';

function Public({ onSuccessLogin }) {
	return (
		<section className={styles.container}>
			<LoginView onSuccessLogin={onSuccessLogin} />
		</section>
	);
}

export default Public;
