import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import useAxios from 'axios-hooks';

import AdminLayout from 'layouts/Admin.jsx';
import PublicLayout from 'layouts/Public.jsx';

import styles from './app.module.scss';
import logo from 'assets/img/alfi-logo.svg';

function App() {
	// const hasToken = Boolean(window.localStorage.getItem('alfi-token'));
	// const [commonData, setCommonData] = useState(hasToken);
	// const [{ data: countriesData, loading: loadingCountries }] = useAxios({
	// 	method: 'GET',
	// 	url: '/profile/countries',
	// });

	// const [{ data: teamsData, loading: loadingTeams }, fetchTeams] = useAxios(
	// 	{
	// 		method: 'GET',
	// 		url: '/profile/countries',
	// 	},
	// 	{ manual: true },
	// );

	// const [{ data: levelsData, loading: loadingLevel }, fetchLevels] = useAxios(
	// 	{
	// 		method: 'GET',
	// 		url: '/admin/level',
	// 	},
	// 	{ manual: true },
	// );

	// function onSuccessLogin() {
	// 	setCommonData(1);
	// }

	// useEffect(() => {
	// 	if (commonData) {
	// 		// Promise.all([fetchTeams(), fetchLevels()]);
	// 		Promise.all([fetchLevels()]);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [commonData]);

	let loadingState = false;

	// if (hasToken) {
	// 	loadingState = loadingCountries || loadingLevel;
	// 	// loadingState = loadingCountries || loadingTeams || loadingLevel;
	// } else {
	// 	loadingState = loadingCountries;
	// }

	if (loadingState) {
		return (
			<div className={styles.loader}>
				<img src={logo} alt="Logo de Alfi" />
				<h1>Cargando...</h1>
			</div>
		);
	}

	return (
		<BrowserRouter>
			<Switch>
				<Route
					path="/admin"
					render={(props) => (
						<AdminLayout
							// countries={countriesData ? countriesData.response : []}
							// levels={levelsData ? levelsData.data : []}
							levels={[]}
							countries={[]}
							// teams={teamsData.response}
							{...props}
						/>
					)}
				/>
				<Route path="/login" render={(props) => <PublicLayout {...props} />} />
				<Redirect from="/" to="/admin/users/products" />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
