import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';

import AdminNavbarLinks from './AdminNavbarLinks.jsx';

class Header extends Component {
	constructor(props) {
		super(props);
		this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
		this.state = {
			sidebarExists: false,
		};
	}
	mobileSidebarToggle(e) {
		e.preventDefault();
		if (this.state.sidebarExists === false) {
			this.setState({
				sidebarExists: true,
			});
		}
		document.documentElement.classList.toggle('nav-open');
		var node = document.createElement('div');
		node.id = 'bodyClick';
		node.onclick = function () {
			this.parentElement.removeChild(this);
			document.documentElement.classList.toggle('nav-open');
		};
		document.body.appendChild(node);
	}
	render() {
		return (
			<Navbar fluid>
				<Navbar.Header>
					<Navbar.Brand>
						<a href="#alfi">{this.props.brandText}</a>
						{/* <div style={{ display: 'inline', padding: '0 10px' }}>
							<select
								onChange={this.props.onChangeGroup}
								style={{
									width: '110px',
								}}
								value={this.props.groupId}
							>
								<option value="">Todos</option>
								{this.props.groups.map((group) => {
									return (
										<option key={group.id} value={group.id}>
											{group.name}
										</option>
									);
								})}
							</select>
						</div> */}
					</Navbar.Brand>
					<Navbar.Toggle onClick={this.mobileSidebarToggle} />
				</Navbar.Header>
				<Navbar.Collapse>
					<AdminNavbarLinks onLogout={this.props.onLogout} />
				</Navbar.Collapse>
			</Navbar>
		);
	}
}

export default Header;
