import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import AdminNavbarLinks from '../Navbars/AdminNavbarLinks.jsx';

import logo from 'assets/img/alfi-logo.svg';

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: window.innerWidth,
		};
	}
	// activeRoute(routeName) {
	// 	return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
	// }
	updateDimensions() {
		this.setState({ width: window.innerWidth });
	}
	componentDidMount() {
		this.updateDimensions();
		window.addEventListener('resize', this.updateDimensions.bind(this));
	}
	render() {
		// const sidebarBackground = {
		// 	backgroundImage: 'url(' + this.props.image + ')',
		// };
		return (
			<div
				id="sidebar"
				className="sidebar"
				data-color={this.props.color}
				data-image={this.props.image}
			>
				{/* {this.props.hasImage ? (
					<div className="sidebar-background" style={sidebarBackground} />
				) : null} */}
				<div className="logo">
					<img src={logo} height="70" width="200" alt="logo_image" />
				</div>
				<div className="sidebar-wrapper">
					<ul className="nav">
						{this.props.routes.map((prop, key) => {
							if (!prop.redirect)
								return (
									<li key={key}>
										<NavLink
											to={prop.layout + prop.path}
											exact={prop.exact}
											className="nav-link"
											activeClassName="active"
										>
											<i className={prop.icon} />
											<p>{prop.name}</p>
										</NavLink>
									</li>
								);
							return null;
						})}
						{this.state.width <= 991 ? (
							<AdminNavbarLinks onLogout={this.props.onLogout} />
						) : null}
					</ul>
				</div>
			</div>
		);
	}
}

export default Sidebar;
