/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import useAxios from 'axios-hooks';
import { Redirect } from 'react-router-dom';
import useForm from 'react-hook-form';
import routes from 'routes.js';
import {
	Alert,
	FormGroup,
	ControlLabel,
	FormControl,
	HelpBlock,
} from 'react-bootstrap';
import CustomButton from 'components/CustomButton/CustomButton';
import logo from 'assets/img/alfi-logo-green.svg';

import styles from './login.module.scss';

function LoginView() {
	const [messageError, setMessageError] = useState('');
	const [goAdmin, setGoAdmin] = useState(false);
	const [{ data, response, loading, error }, executePost] = useAxios(
		{
			url: '/auth/login',
			method: 'POST',
		},
		{ manual: true },
	);
	const { register, handleSubmit, formState, errors } = useForm();

	if (goAdmin) {
		window.localStorage.setItem('alfi-token', data.response.access_token);
		return <Redirect to={routes[0].path} />;
	}

	if (response) {
		setGoAdmin(true);
	}

	if (error && !messageError) {
		if (error.response.status === 401) { 
			setMessageError('Usuario o contraseña incorrectos.');
		} else {
			setMessageError('Estamos teniendo problemas internos, por favor intenta en unos minutos.');
		}
	}

	function onSubmit(data2) {
		if (formState.isValid) {
			setMessageError('');
			executePost({ data: data2 });
		}
	}

	return (
		<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
			<div style={{ marginBottom: '20px', textAlign: 'center' }}>
				<img src={logo} height="70" width="200" alt="Logo Alfi - Fondo Verde" />
			</div>
			{
				messageError && (
					<Alert bsStyle="danger">
						<strong>{messageError}</strong>
					</Alert>
				)
			}
			<FormGroup
				validationState={
					formState.dirty || formState.isSubmitted
						? errors.email
							? 'error'
							: 'success'
						: null
				}
			>
				<ControlLabel htmlFor="email">Email</ControlLabel>
				<FormControl
					bsSize="lg"
					id="email"
					name="email"
					type="email"
					inputRef={register({
						required: true,
						pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						type: 'email',
					})}
				/>
				<HelpBlock>
					{errors.email &&
						errors.email.type === 'required' &&
						'Ingresa un email'}
					{errors.email &&
						errors.email.type === 'pattern' &&
						'Ese email no es válido'}
				</HelpBlock>
			</FormGroup>
			<FormGroup
				validationState={
					formState.dirty || formState.isSubmitted
						? errors.password
							? 'error'
							: 'success'
						: null
				}
			>
				<ControlLabel htmlFor="password">Contraseña</ControlLabel>
				<FormControl
					bsSize="lg"
					id="password"
					name="password"
					type="password"
					inputRef={register({ required: true })}
				/>
				<HelpBlock>{errors.password && 'Ingresa tu contraseña'}</HelpBlock>
			</FormGroup>
			<div className={styles.actions}>
				<CustomButton disabled={loading} bsStyle="success" fill type="submit">
					Ingresar
				</CustomButton>
			</div>
		</form>
	);
}

export default LoginView;
