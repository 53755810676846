import React, { Fragment, useEffect, useRef, useState } from 'react';
import useAxios from 'axios-hooks';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';
import {
	Col,
	Grid,
	Row,
	Table,
	FormGroup,
	ControlLabel,
	FormControl,
	Modal,
} from 'react-bootstrap';
import { Card } from 'components/Card/Card.jsx';
import CustomButton from 'components/CustomButton/CustomButton';

function UsersView({ groupId, handleClick }) {
	let searchRef = useRef();
	const [showModal, setShowModal] = useState(false);
	const [currentProduct, setCurrentProduct] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [currentSearch, setCurrentSearch] = useState(null);
	const [{ data, loading, response: responseGet }, executeGet] = useAxios(
		{
			method: 'GET',
			url: 'admin/user/register-products2',
			params: {
				page: currentPage,
				search: currentSearch,
			},
		},
		{ manual: true },
	);

	const [
		{ loading: loadingExport, response: responseExport },
		executeExport,
	] = useAxios(
		{
			method: 'GET',
			url: '/admin/user/products-export2',
			responseType: 'blob',
		},
		{
			manual: true,
		},
	);

	useEffect(() => {
		executeGet();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, currentSearch, groupId]);

	useEffect(() => {
		if (responseGet) {
			setTotalPages(data.last_page);
		}
	}, [responseGet, data]);

	useEffect(() => {
		if (responseExport && responseExport.status === 200) {
			const blob = new Blob([responseExport.data], { type: 'octet/stream' });
			const url = window.URL.createObjectURL(blob);
			const aEl = window.document.createElement('a');
			aEl.download = 'Usuarios-Mundo-Financiero.xlsx';
			aEl.href = url;
			aEl.click();
			handleClick({ message: 'Excel generado correctamente' });
		}
	}, [responseExport, handleClick]);

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function onSubmit(e) {
		e.preventDefault();
		setCurrentSearch(searchRef.value || null);
	}

	function showMultipleModal() {
		executeExport();
	}

	function onShowModal(record) {
		const data = {
			productName: record.product ? record.product.name : record.product_name,
			productLogo: record.product ? record.product.url_logo_header : '',
			companyName: record.company ? record.company.name : '',
			regionName: record.region ? record.region.name : '',
			categoryName: record.product ? record.product.category.name : '',
		};
		setCurrentProduct(data);
		setShowModal(true);
	}

	function onCloseModal() {
		setShowModal(false);
	}

	return (
		<div className="content">
			<Grid fluid>
				<Row>
					<Col md={12}>
						<Card
							title="Usuarios"
							ctTableFullWidth
							ctTableResponsive
							content={
								<Fragment>
									<Grid fluid>
										<Row>
											<Col sm={12} md={6}>
												<form onSubmit={onSubmit}>
													<FormGroup>
														<ControlLabel>Buscar</ControlLabel>
														<FormControl
															inputRef={(ref) => (searchRef = ref)}
															name="search"
															type="search"
														/>
													</FormGroup>
												</form>
											</Col>
											<Col sm={12} md={6} style={{ marginTop: '23px' }}>
												<Grid fluid>
													<Row>
														<br className="hidden-sm hidden-md hidden-lg" />
														<Col sm={4} md={3} lg={2}>
															<CustomButton
																aria-label="Exportar Usuarios"
																block
																bsStyle="success"
																disabled={loadingExport}
																fill
																title="Exportar Usuarios"
																type="button"
																onClick={() => showMultipleModal('export')}
															>
																<i
																	className="fa fa-download"
																	aria-hidden="true"
																/>
																<span className="hidden-md hidden-lg">
																	Exportar Usuarios
																</span>
															</CustomButton>
														</Col>
													</Row>
												</Grid>
											</Col>
										</Row>
									</Grid>
									{!loading ? (
										<Table striped hover>
											<thead>
												<tr>
													<th>Usuario</th>
													<th className="hidden-md hidden-sm hidden-xs">
														Email
													</th>
													<th>Nivel</th>
													<th>Producto</th>
													<th>Teléfono</th>
													<th>Fecha de Registro</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{data &&
													data.data &&
													data.data.map((user, key) => {
														const cd = new Date(
															user.created_at,
														).toLocaleDateString('es-PE', {
															day: '2-digit',
															year: 'numeric',
															month: '2-digit',
															minute: '2-digit',
															hour: '2-digit',
															timeZone: 'America/Lima',
														});
														return (
															<tr key={key}>
																<td>{`${user.first_name} ${user.last_name}`}</td>
																<td className="hidden-md hidden-sm hidden-xs">
																	{user.email}
																</td>
																<td>{user.level_name}</td>
																<td>
																	{user.product
																		? user.product.name
																		: user.product_name}
																</td>
																<td>{user.phone}</td>
																<td>{cd}</td>
																<td>
																	<CustomButton
																		bsStyle="info"
																		onClick={() => onShowModal(user)}
																	>
																		Ver Más
																	</CustomButton>
																</td>
															</tr>
														);
													})}
											</tbody>
										</Table>
									) : (
										<i className="fa fa-spinner fa-spin fa-2x fa-fw" />
									)}
									<Modal
										show={showModal}
										className="static-modal"
										onHide={onCloseModal}
									>
										{currentProduct && (
											<Fragment>
												<Modal.Header closeButton>
													<Modal.Title>
														{currentProduct.productName}
													</Modal.Title>
												</Modal.Header>
												<Modal.Body>
													<ul>
														<li>
															Categoría:
															{currentProduct.categoryName}
														</li>
														<li>
															Empresa:
															{currentProduct.companyName}
														</li>
														<li>
															Región:
															{currentProduct.regionName}
														</li>
														{currentProduct.productLogo && (
															<li>
																Logo{' '}
																<img
																	src={currentProduct.productLogo}
																	width="200"
																	height="42"
																	alt={currentProduct.productName}
																/>
															</li>
														)}
													</ul>
												</Modal.Body>
											</Fragment>
										)}
									</Modal>
								</Fragment>
							}
						/>
					</Col>
					<Col md={12}>
						<div className="pull-right">
							<UltimatePagination
								currentPage={currentPage}
								totalPages={totalPages}
								onChange={onPageChange}
							/>
						</div>
					</Col>
				</Row>
			</Grid>
		</div>
	);
}

export default UsersView;
